/* You can add global styles to this file, and also import other style files */

:root {
    --list-icon-color: #1c71d8;
    --card-border-color: #d2cdd7;
    --translucent-background-color: #F0F0F0CC;
    --material-theme-color: #eaeefa;
    --tab-selected-color: #5296E754;

    --success-color: #4ed223;
    --warn-color: #f44336;
    --log-warning-color: #ffaf00;
    --error-color: #ff0000;
    --debug-color: #7e7cee;
}

.mat-mdc-dialog-container .mdc-dialog__container {
    --mdc-dialog-container-shape: 30px;
    --mdc-dialog-container-color: #e2e9f8;
    --mdc-dialog-container-elevation: none;
}

html, body {
    height: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

ion-split-pane {
    padding-top: env(safe-area-inset-top);
}

app-menubar {
    display: block;
    background: var(--material-theme-color);
    padding-top: env(safe-area-inset-top);
}

.mat-mdc-menu-panel {
    --mat-menu-container-color: var(--material-theme-color);
    --mat-menu-container-shape: 20px !important;
}

mat-toolbar {
    padding-left: 0 !important;

    .title {
        margin-left: 10px;
    }
}

mat-action-list {
    padding: 0 !important;
}

button:not(.mdc-icon-button) {
    border-radius: 20px !important;
}

.notRendered {
    display: none;
}

.translucent {
    backdrop-filter: saturate(180%) blur(20px);
    background: var(--translucent-background-color);
}

.dialog-title {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0.2em;
    padding: 0;
}

.toolbarSpacer {
    flex: 1 1 auto;
}

.mat-toolbar {
    background: var(--material-theme-color) !important;
}

mat-expansion-panel, mat-card {
    box-shadow: none !important;
    background: var(--material-theme-color) !important;
    border-radius: 20px !important;
    overflow: hidden !important;
}

mat-action-list {
    background: var(--material-theme-color);
    border-radius: 20px;
    overflow: hidden;

    fa-icon[matListItemIcon] {
        margin-right: 0 !important;
        --fa-primary-color: var(--list-icon-color);
        --fa-secondary-color: var(--list-icon-color);
        color: var(--list-icon-color) !important;
        height: unset !important;
    }
}

.mat-mdc-table {
    background: transparent !important;
}

.iconButton {
    fa-icon + span {
        margin-left: 5px;
    }
}

.bigButton {
    width: 100%;
    height: 50px !important;
    max-width: 400px;
    display: block !important;
    margin: 0 auto 20px;
}

.actionButtons {
    width: 100%;
    text-align: center;
}

.smallButton {
    display: inline-block !important;
    margin-right: 10px;
    margin-left: 10px;
}

ion-menu {
    max-width: 350px;
}

ion-toolbar.md {
    --background: var(--material-theme-color);
}

.content {
    padding: 20px;
}

swiper-container {
    touch-action: pan-y;
}

.allow-line-feed {
    white-space: pre-line !important;
}

.fullscreen-modal {
    padding-top: env(safe-area-inset-top);

    --height: 100%;
    --max-height: 100%;
    --width: 100%;
    --max-width: 100%;
}

.status-bar-background {
    background: var(--material-theme-color);
    position: fixed;
    top: 0;
    width: 100%;
    height: env(safe-area-inset-top);
}

ion-popover {
    --width: auto;
    width: auto;
}

ion-button {
    text-transform: none;
}

.clickable {
    cursor: pointer;
}

.app-safe-area-margin {
    margin-top: env(safe-area-inset-top);
}
